import { useGLTF } from '@react-three/drei';
import { useLayoutEffect, useMemo } from 'react';
import type { Object3D } from 'three';
import { Camera, Mesh } from 'three';

import { useVisualizerContext } from './VisualizerContext';

export function useRobotPart(path: string) {
  const { material } = useVisualizerContext();

  const gltf = useGLTF(path);

  const robotPart = useMemo(() => {
    // clone the part so it can appear multiple times in the scene
    // (as ghost, or repeated gripper fingers, or multiple gripper instances etc.)
    return gltf.scene.clone(true);
  }, [gltf.scene]);

  useLayoutEffect(() => {
    const childrenToRemove: Object3D[] = [];

    robotPart.traverse((child) => {
      if (child instanceof Mesh) {
        if (material) {
          /* eslint-disable-next-line no-param-reassign */
          child.material = material;
        } else if (child.material) {
          // This affects how shiny and dark the surface is.
          // 1 == default model material, and 0 is pretty matte.

          /* eslint-disable-next-line no-param-reassign */
          child.material.metalness = 0.5;
        }
      } else if (child instanceof Camera) {
        // there are lots of unnecessary perspective cameras in the models which we can remove
        childrenToRemove.push(child);
      }
    });

    childrenToRemove.forEach((child) => {
      child.parent?.remove(child);
    });
  }, [robotPart, material]);

  return robotPart;
}
