import { useEffect, useMemo, useState } from 'react';

import { RobotPart } from '../../RobotPart';
import type { GetGripperState, JointMapping } from '../../types';
import { useRobotModel } from '../../useRobotModel';
import { useRobotUpdateOnStateChange } from '../../useRobotUpdateOnStateChange';
import { useVisualizerContext } from '../../VisualizerContext';

import urdfPath from './3FG15.urdf';
import bodPath from './3FG15_BOD.glb';
import fg1Path from './3FG15_FG_POS1.glb';
import fg2Path from './3FG15_FG_POS2.glb';
import fg3Path from './3FG15_FG_POS3.glb';
import fgtPath from './3FG15_FGT_13-5.glb';

const FINGERTIP_POS1_Z = 0.0355;
const FINGERTIP_POS2_Z = 0.0445;
const FINGERTIP_POS3_Z = 0.0535;

const GET_GRIPPER_STATE_DEFAULT: GetGripperState = (state) =>
  state.gripperState;

interface OnRobot3FG15Props {
  getGripperState?: GetGripperState;
}

export function OnRobot3FG15({
  getGripperState = GET_GRIPPER_STATE_DEFAULT,
}: OnRobot3FG15Props) {
  const model = useRobotModel(urdfPath);
  const [fgPath, setFgPath] = useState<string>(fg3Path);

  const { onStateChange } = useVisualizerContext();

  useEffect(() => {
    return onStateChange(
      (state) => {
        const gripperState = getGripperState(state);

        return gripperState?.kind === 'OnRobot3FG15'
          ? gripperState.fingerPosition
          : null;
      },
      Object.is,
      (fingerPosition) => {
        const setModelFingertipPosition = (z: number) => {
          model.joints.fingertip0.position.setZ(z);
          model.joints.fingertip1.position.setZ(z);
          model.joints.fingertip2.position.setZ(z);
        };

        switch (fingerPosition) {
          case 1:
            setFgPath(fg1Path);
            setModelFingertipPosition(FINGERTIP_POS1_Z);
            break;
          case 2:
            setFgPath(fg2Path);
            setModelFingertipPosition(FINGERTIP_POS2_Z);
            break;
          default:
            setFgPath(fg3Path);
            setModelFingertipPosition(FINGERTIP_POS3_Z);
        }
      },
    );
  }, [onStateChange, model, getGripperState]);

  const joints = useMemo<JointMapping>(() => {
    return [
      [
        'finger0',
        (state) => {
          const gripperState = getGripperState(state);

          return gripperState?.kind === 'OnRobot3FG15'
            ? gripperState.fingerAngle
            : undefined;
        },
      ],
    ];
  }, [getGripperState]);

  const isRobotVisible = useRobotUpdateOnStateChange(model, joints);

  return (
    <group visible={isRobotVisible}>
      <RobotPart path={bodPath} source={model.visual.gripper_link} />
      <RobotPart path={fgPath} source={model.visual.finger0} />
      <RobotPart path={fgtPath} source={model.visual.fingertip0} />
      <RobotPart path={fgPath} source={model.visual.finger1} />
      <RobotPart path={fgtPath} source={model.visual.fingertip1} />
      <RobotPart path={fgPath} source={model.visual.finger2} />
      <RobotPart path={fgtPath} source={model.visual.fingertip2} />
    </group>
  );
}
