import { useEffect, useState } from 'react';

import { useVisualizerContext } from '../VisualizerContext';

export function useDynamicBaseKind() {
  const { onStateChange, overrideState } = useVisualizerContext();

  const [dynamicBaseKind, setDynamicBaseKind] = useState<string | undefined>();

  // don't render dynamic base if only rendering the tooltip at a specified position
  const shouldDynamicBaseRender = !overrideState?.tooltipPoint;

  useEffect(() => {
    if (!shouldDynamicBaseRender) {
      setDynamicBaseKind(undefined);

      return undefined;
    }

    return onStateChange(
      (state) => state.dynamicBaseState?.kind,
      Object.is,
      setDynamicBaseKind,
    );
  }, [onStateChange, shouldDynamicBaseRender]);

  return dynamicBaseKind;
}
