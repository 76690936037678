import { Arm } from './arm/Arm';
import { ArmBase } from './armBase/ArmBase';
import { EndEffector } from './endEffector/EndEffector';

export function Robot() {
  return (
    <ArmBase>
      <Arm>
        <EndEffector />
      </Arm>
    </ArmBase>
  );
}
