import { useDynamicBaseKind } from './useDynamicBaseKind';
import { VerticalLiftBase } from './VerticalLiftBase';

interface ArmBaseProps {
  children: React.ReactNode;
}

export function ArmBase({ children }: ArmBaseProps) {
  const dynamicBaseKind = useDynamicBaseKind();

  if (dynamicBaseKind === 'EwellixLiftTLT') {
    return <VerticalLiftBase>{children}</VerticalLiftBase>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
