import { useFrame } from '@react-three/fiber';
import type { Object3D } from 'three';

import { CommonQuaternions } from '@sb/geometry';

export function useCopyRobotModelPosition(
  source: Object3D | null | undefined,
  targetRef: React.RefObject<Object3D | null | undefined>,
) {
  useFrame(() => {
    const target = targetRef.current;

    if (source && target) {
      source.getWorldPosition(target.position);
      source.getWorldQuaternion(target.quaternion);
      target.quaternion.multiply(CommonQuaternions.ROTATE_X_90);
    } else if (target) {
      target.position.set(0, 0, 0);
      target.quaternion.set(0, 0, 0, 0);
    }
  });
}
