import { useRef } from 'react';
import type { Object3D } from 'three';

import { useCopyRobotModelPosition } from './useCopyRobotModelPosition';
import { useRobotPart } from './useRobotPart';

interface RobotPartProps {
  path: string;
  source?: Object3D;
}

export function RobotPart({ path, source }: RobotPartProps) {
  const part = useRobotPart(path);
  const ref = useRef<Object3D>(null);

  useCopyRobotModelPosition(source, ref);

  return <primitive ref={ref} object={part} />;
}
