import { createContext, useContext } from 'react';
import type { Material } from 'three';

import type { KinematicState } from '@sb/routine-runner';

import type { AxesMode, OnVisualizerStateChange } from './types';

interface VisualizerContextType {
  onStateChange: OnVisualizerStateChange;
  overrideState?: Partial<KinematicState>;
  axesMode: AxesMode;
  material?: Material;
}

const VisualizerContext = createContext<VisualizerContextType>({
  onStateChange: () => () => {},
  axesMode: null,
});

export const VisualizerContextProvider = VisualizerContext.Provider;

export function useVisualizerContext() {
  return useContext(VisualizerContext);
}
