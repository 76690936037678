import { Html } from '@react-three/drei';

import { Loader } from '@sb/ui/components';

export function LoaderFallback() {
  return (
    <Html transform sprite distanceFactor={1} pointerEvents="none">
      <Loader size="large" />
    </Html>
  );
}
