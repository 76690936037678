import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { RobotPart } from '../../RobotPart';
import type { GetGripperState } from '../../types';
import { useVisualizerContext } from '../../VisualizerContext';
import { OnRobot2FG7 } from '../OnRobot2FG7/OnRobot2FG7';
import { OnRobot3FG15 } from '../OnRobot3FG15/OnRobot3FG15';
import { useA2Mounting } from '../useA2Mounting';

import eeA12QCPath from './EE-A1-2QC.glb';
import eeA22QCPath from './EE-A2-2QC.glb';

const GET_GRIPPER_STATE_PRIMARY: GetGripperState = (state) =>
  state.gripperState?.kind === 'OnRobotDualQuickChanger'
    ? state.gripperState.primary
    : undefined;

const GET_GRIPPER_STATE_SECONDARY: GetGripperState = (state) =>
  state.gripperState?.kind === 'OnRobotDualQuickChanger'
    ? state.gripperState.secondary
    : undefined;

function getEndEffectorNode(
  kind: string | undefined,
  getGripperState: GetGripperState,
) {
  switch (kind) {
    case 'OnRobot3FG15':
      return <OnRobot3FG15 getGripperState={getGripperState} />;
    case 'OnRobot2FG7':
      return <OnRobot2FG7 />;

    default:
      return null;
  }
}

interface DualEndEffectorsKinds {
  primary?: string;
  secondary?: string;
}

export function OnRobotDualQuickChanger() {
  const { onStateChange } = useVisualizerContext();

  const [endEffectorsKind, setEndEffectorsKind] =
    useState<DualEndEffectorsKinds>({});

  useEffect(() => {
    return onStateChange(
      (state) => {
        return state.gripperState?.kind === 'OnRobotDualQuickChanger'
          ? {
              primary: state.gripperState.primary?.kind,
              secondary: state.gripperState.secondary?.kind,
            }
          : {};
      },
      isEqual,
      setEndEffectorsKind,
    );
  }, [onStateChange]);

  const isA2 = useA2Mounting();

  return (
    <group
      position={[0, isA2 ? 0.009 : 0.016, 0]}
      rotation={[Math.PI / 2, -Math.PI, Math.PI]}
    >
      <RobotPart path={isA2 ? eeA22QCPath : eeA12QCPath} />

      <group
        position={[-0.0464, 0, -0.0663]}
        rotation={[0, -Math.PI / 6, -Math.PI / 2]}
      >
        {getEndEffectorNode(
          endEffectorsKind.primary,
          GET_GRIPPER_STATE_PRIMARY,
        )}
      </group>

      <group
        position={[0.0464, 0, -0.0663]}
        rotation={[0, Math.PI / 6, Math.PI / 2]}
      >
        {getEndEffectorNode(
          endEffectorsKind.secondary,
          GET_GRIPPER_STATE_SECONDARY,
        )}
      </group>
    </group>
  );
}
