import type { Object3D } from 'three';

import { useVisualizerContext } from '../VisualizerContext';

import { JointAxes } from './JointAxes';
import { TooltipAxesBaseFrame } from './TooltipAxesBaseFrame';
import { TooltipAxesTooltipFrame } from './TooltipAxesTooltipFrame';

interface AxesProps {
  joints: Record<string, Object3D>;
}

export function Axes({ joints }: AxesProps) {
  const { axesMode } = useVisualizerContext();

  return (
    <>
      {axesMode === 'robotArm' && (
        <TooltipAxesBaseFrame tooltip={joints.tool_tip} />
      )}
      {axesMode === 'tooltip' && (
        <TooltipAxesTooltipFrame tooltip={joints.tool_tip} />
      )}
      {axesMode === 'joints' && <JointAxes joints={joints} />}
    </>
  );
}
