import type { Loader } from '@react-three/fiber';
import { useLoader } from '@react-three/fiber';
import { useMemo } from 'react';
import type { Object3D } from 'three';
import type {
  URDFJoint,
  URDFMimicJoint,
  URDFRobot,
  URDFVisual,
} from 'urdf-loader';
import URDFLoader from 'urdf-loader';

function isURDFJoint(object: Object3D): object is URDFJoint {
  return object.type === 'URDFJoint';
}

function isURDFMimicJoint(object: Object3D): object is URDFMimicJoint {
  return object.type === 'URDFMimicJoint';
}

function isURDFVisual(object: Object3D): object is URDFVisual {
  return object.type === 'URDFVisual';
}

/* eslint-disable no-param-reassign */

export function useRobotModel(path: string): URDFRobot {
  const baseRobot: URDFRobot = useLoader(
    URDFLoader as unknown as { new (): Loader<URDFRobot> },
    path,
    (loader) => {
      const urdfLoader = loader as unknown as URDFLoader;

      urdfLoader.loadMeshCb = (_assetPath, _manager, done) => done(null!);
    },
  );

  return useMemo(() => {
    // clone the robot so we can have multiple copies (main and ghost)
    const robot = baseRobot.clone();

    // process the robot
    robot.traverse((child) => {
      if (isURDFJoint(child)) {
        // turn off limits because the visualizer shouldn't break when trying to display
        // an out-of-limits position
        child.ignoreLimits = true;
        // regenerate this array because clone doesn't work properly
        child.mimicJoints.length = 0;
      } else if (isURDFMimicJoint(child) && child.mimicJoint) {
        child.ignoreLimits = true;
        robot.joints[child.mimicJoint as string].mimicJoints.push(child);
      } else if (isURDFVisual(child) && child.parent?.name) {
        // the name is linked to the parent joint not the visual node
        robot.visual[child.parent.name] = child;
      }
    });

    return robot;
  }, [baseRobot]);
}
