import { invalidate } from '@react-three/fiber';
import { debounce } from 'lodash';
import { useLayoutEffect } from 'react';

interface UseFullPageSizeArgs {
  containerStyle?: CSSStyleDeclaration;
  isFullPage?: boolean;
}

/**
 * There are some problems positioning <Html> elements on Safari at some specific heights/widths of the canvas
 * This hook ensures the canvas height and width is always a multiple of 100
 */
export function useFullPageVisualizer({
  containerStyle,
  isFullPage,
}: UseFullPageSizeArgs): void {
  useLayoutEffect(() => {
    /* eslint-disable no-param-reassign */

    if (!containerStyle) {
      return undefined;
    }

    if (!isFullPage) {
      containerStyle.height = '';
      containerStyle.width = '';

      return undefined;
    }

    // After a resize, drei `<Html>` label elements sometimes disappear,
    // and need a kick to render correctly.
    // A slightly delayed `invalidate` seems to fix this consistently.
    const debouncedInvalidate = debounce(() => {
      invalidate();
    }, 500);

    const setSize = () => {
      const SNAP_TO_SIZE = 100;

      const newHeight = `${
        Math.ceil(window.innerHeight / SNAP_TO_SIZE) * SNAP_TO_SIZE
      }px`;

      if (newHeight !== containerStyle.height) {
        containerStyle.height = newHeight;
        debouncedInvalidate();
      }

      const newWidth = `${
        Math.ceil(window.innerWidth / SNAP_TO_SIZE) * SNAP_TO_SIZE
      }px`;

      if (newWidth !== containerStyle.width) {
        containerStyle.width = newWidth;
        debouncedInvalidate();
      }
    };

    setSize();
    window.addEventListener('resize', setSize);

    return () => window.removeEventListener('resize', setSize);
  }, [containerStyle, isFullPage]);
}
