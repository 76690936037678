import type { Object3D } from 'three';

import { CommonQuaternions } from '@sb/geometry';

import { Axis } from './Axis';

const AXIS_COLOR = '--standard-bots--lavender';

interface JointAxesProps {
  joints: Record<string, Object3D>;
}

export function JointAxes({ joints }: JointAxesProps) {
  return (
    <>
      <Axis
        label="J0"
        source={joints.joint0}
        rotation={CommonQuaternions.ROTATE_Y_90}
        reverseArrow
        color={AXIS_COLOR}
      />
      <Axis
        label="J1"
        source={joints.joint1}
        rotation={CommonQuaternions.ROTATE_Z_90}
        color={AXIS_COLOR}
      />
      <Axis
        label="J2"
        source={joints.joint2}
        rotation={CommonQuaternions.ROTATE_Z_90}
        color={AXIS_COLOR}
      />
      <Axis
        label="J3"
        source={joints.joint3}
        rotation={CommonQuaternions.ROTATE_Z_270}
        reverseArrow
        color={AXIS_COLOR}
      />
      <Axis
        label="J4"
        source={joints.joint4}
        rotation={CommonQuaternions.ROTATE_Y_270}
        color={AXIS_COLOR}
      />
      <Axis
        label="J5"
        source={joints.joint5}
        rotation={CommonQuaternions.ROTATE_Z_270}
        reverseArrow
        color={AXIS_COLOR}
      />
    </>
  );
}
