import { useCallback } from 'react';

import type { OnVisualizerStateChange } from '@sb/visualizer';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

export default function useOnVisualizerStateChange(
  isVizbot: boolean,
  robotID: string | null,
) {
  const routineRunnerHandle = useRoutineRunnerHandle({
    isVizbot,
    robotID,
  });

  const onRoutineRunnerStateChange = useCallback<OnVisualizerStateChange>(
    (selector, isEqual, onChange) => {
      return routineRunnerHandle.onStateChange(
        (state) => selector(state.kinematicState),
        isEqual,
        onChange,
      );
    },
    [routineRunnerHandle],
  );

  return onRoutineRunnerStateChange;
}
