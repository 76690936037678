import { RobotPart } from '../../RobotPart';

import glbPath from './Screwdriver.glb';

export function OnRobotScrewdriver() {
  return (
    <group position={[0, -0.0815, 0.167]} rotation={[0, Math.PI, 0]}>
      <RobotPart path={glbPath} />
    </group>
  );
}
