import cx from 'classnames';
import { Suspense, useState } from 'react';

import type { KinematicState } from '@sb/routine-runner';
import { Loader } from '@sb/ui/components';
import { six } from '@sb/utilities';
import type { AxesMode } from '@sb/visualizer';
import { VisualizerScene } from '@sb/visualizer';
import { useIsRobotConnected, useVisualizerChildren } from '@sbrc/hooks';

import { WaitForConfirmationModal } from '../notifications';

import { useFullPageVisualizer } from './useFullPageVisualizer';
import useOnVisualizerStateChange from './useOnVisualizerStateChange';

import styles from './RobotVisualizer.module.css';

export const VISUALIZER_STATE_DEFAULT_POSITION: Partial<KinematicState> = {
  jointAngles: six(0),
};

interface RobotVisualizerProps {
  className?: string;
  isVizbot: boolean;
  robotID: string;
  axesMode?: AxesMode;
  showOtherRobotGhost?: boolean;
  ghostRobotState?: Partial<KinematicState>;
  isFullPage?: boolean;
}

export default function RobotVisualizer({
  className,
  isVizbot,
  robotID,
  axesMode,
  showOtherRobotGhost,
  ghostRobotState,
  isFullPage,
}: RobotVisualizerProps) {
  const isLiveRobotConnected = useIsRobotConnected({ robotID });

  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const isControllingLiveRobotDisconnected = !isLiveRobotConnected && !isVizbot;

  const onStateChange = useOnVisualizerStateChange(isVizbot, robotID);

  useFullPageVisualizer({
    containerStyle: containerElement?.style,
    isFullPage,
  });

  const otherRobotOnStateChange = useOnVisualizerStateChange(
    !isVizbot,
    robotID,
  );

  const onGhostRobotStateChange = (() => {
    if (isControllingLiveRobotDisconnected || ghostRobotState) {
      return onStateChange;
    }

    if (showOtherRobotGhost) {
      return otherRobotOnStateChange;
    }

    return undefined;
  })();

  const { visualizerChildren } = useVisualizerChildren();

  return (
    <div
      ref={setContainerElement}
      className={cx(
        styles.robotVisualizer,
        isFullPage && styles.isFullPage,
        className,
      )}
    >
      <Suspense fallback={<Loader className={styles.loader} />}>
        <VisualizerScene
          onStateChange={
            isControllingLiveRobotDisconnected ? undefined : onStateChange
          }
          axesMode={ghostRobotState ? null : axesMode}
          onGhostRobotStateChange={onGhostRobotStateChange}
          ghostRobotState={
            isControllingLiveRobotDisconnected
              ? VISUALIZER_STATE_DEFAULT_POSITION
              : ghostRobotState
          }
          ghostRobotAxesMode={ghostRobotState ? axesMode : null}
        >
          {visualizerChildren}
        </VisualizerScene>
      </Suspense>

      <WaitForConfirmationModal
        isVizbot
        robotID={robotID}
        shouldOpenAutomatically
      />
    </div>
  );
}
