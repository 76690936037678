import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import type { URDFRobot } from 'urdf-loader';

import { isApproximatelyEqual } from '@sb/utilities';

import type { JointMapping } from './types';
import { useVisualizerContext } from './VisualizerContext';

// If any joint has moved more than the epsilon value then trigger a render
const JOINT_EPSILON = 0.001;

export function useRobotUpdateOnStateChange(
  model: URDFRobot,
  joints: JointMapping,
) {
  const { onStateChange, overrideState } = useVisualizerContext();

  // the robot isn't rendered until the first state change is applied
  const [isRobotVisible, setIsRobotVisible] = useState<boolean>(false);

  const invalidate = useThree((state) => state.invalidate);

  useEffect(() => {
    const visualizerStateChangeJoints: JointMapping = [];

    for (const [modelJoint, getValue] of joints) {
      const overridePosition = overrideState && getValue(overrideState);

      if (!model.joints[modelJoint]) {
        // eslint-disable-next-line no-console
        console.warn(`Joint ${modelJoint} does not exist in the URDF model`);
      } else if (overridePosition === undefined) {
        visualizerStateChangeJoints.push([modelJoint, getValue]);
      } else {
        model.joints[modelJoint].setJointValue(overridePosition);
      }
    }

    if (visualizerStateChangeJoints.length === 0) {
      invalidate();
      setIsRobotVisible(true);

      return undefined;
    }

    return onStateChange(
      (state) =>
        visualizerStateChangeJoints.map(
          ([modelJoint, getValue]) =>
            [modelJoint, getValue(state) ?? NaN] as const,
        ),

      (valuesA, valuesB) =>
        valuesA.every((_, index) =>
          isApproximatelyEqual(
            valuesA[index][1],
            valuesB[index][1],
            JOINT_EPSILON,
          ),
        ),

      (jointValues) => {
        let hasChanges = false;

        for (const [modelJoint, jointValue] of jointValues) {
          if (!Number.isNaN(jointValue)) {
            hasChanges = true;
            model.joints[modelJoint].setJointValue(jointValue);
          }
        }

        if (hasChanges) {
          invalidate();
          setIsRobotVisible(true);
        }
      },
    );
  }, [onStateChange, joints, model, overrideState, invalidate]);

  return isRobotVisible;
}
