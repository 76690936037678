import { RobotPart } from '../../RobotPart';

import glbPath from './VGP20.glb';

export function OnRobotVGP20() {
  return (
    <group
      position={[0.036, -0.0636, 0]}
      rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
    >
      <RobotPart path={glbPath} />
    </group>
  );
}
