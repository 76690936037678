import { useEffect, useState } from 'react';

import { useVisualizerContext } from '../VisualizerContext';

export function useEndEffectorKind() {
  const { onStateChange } = useVisualizerContext();

  const [endEffectorKind, setEndEffectorKind] = useState<string | undefined>();

  useEffect(() => {
    return onStateChange(
      (state) => state.gripperState?.kind,
      Object.is,
      setEndEffectorKind,
    );
  }, [onStateChange]);

  return endEffectorKind;
}
