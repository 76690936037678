import { Box } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import type { Group, Mesh } from 'three';

import { useVisualizerContext } from '../VisualizerContext';

interface VerticalLiftBaseProps {
  children: React.ReactNode;
}

// vertical lift width / length dimensions
const LIFT_WIDTH = 0.3;
const LIFT_COLOR = '#d4d7d8';

export function VerticalLiftBase({ children }: VerticalLiftBaseProps) {
  const { onStateChange } = useVisualizerContext();

  const invalidate = useThree((state) => state.invalidate);

  const boxRef = useRef<Mesh>(null);

  const groupRef = useRef<Group>(null);

  useEffect(() => {
    return onStateChange(
      (state) => state.dynamicBaseState?.heightMeters,
      Object.is,
      (heightMeters = 0) => {
        const group = groupRef.current;

        if (!group) {
          return;
        }

        if (boxRef.current && group) {
          // offset y-axis position
          boxRef.current.position.z = heightMeters / 2;
          boxRef.current.scale.z = heightMeters;

          group.position.z = heightMeters;

          invalidate();
        }
      },
    );
  }, [onStateChange, invalidate]);

  return (
    <>
      <Box ref={boxRef} args={[LIFT_WIDTH, LIFT_WIDTH, 1]}>
        <meshStandardMaterial color={LIFT_COLOR} />
      </Box>
      <group ref={groupRef}>{children}</group>
    </>
  );
}
