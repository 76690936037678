import { RobotPart } from '../../RobotPart';

import bodPath from './2FG7_BOD.glb';
import fgtPath from './2FG7_FGT_16-5.glb';

export function OnRobot2FG7() {
  // TODO animate fingers on state change when 2FG7 URDF is available

  return (
    <>
      <RobotPart path={bodPath} />
      <group position-x={0.03} position-y={-0.1} rotation-y={Math.PI}>
        <RobotPart path={fgtPath} />
      </group>
      <group position-x={-0.03} position-y={-0.1}>
        <RobotPart path={fgtPath} />
      </group>
    </>
  );
}
