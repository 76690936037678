import { RobotPart } from '../../RobotPart';
import { OnRobot2FG7 } from '../OnRobot2FG7/OnRobot2FG7';
import { OnRobot3FG15 } from '../OnRobot3FG15/OnRobot3FG15';
import { OnRobotScrewdriver } from '../OnRobotScrewdriver/OnRobotScrewdriver';
import { OnRobotVGP20 } from '../OnRobotVGP20/OnRobotVGP20';
import { useA2Mounting } from '../useA2Mounting';
import { useEndEffectorKind } from '../useEndEffectorKind';

import eeA1QCPath from './EE-A1-QC.glb';
import eeA2QCPath from './EE-A2-QC.glb';

function getEndEffectorNode(kind: string | undefined) {
  switch (kind) {
    case 'OnRobot3FG15':
      return <OnRobot3FG15 />;
    case 'OnRobot2FG7':
      return <OnRobot2FG7 />;
    case 'OnRobotScrewdriver':
      return <OnRobotScrewdriver />;
    case 'OnRobotVGP20':
      return <OnRobotVGP20 />;
    default:
      return null;
  }
}

export function QuickChanger() {
  const endEffectorKind = useEndEffectorKind();

  const isA2 = useA2Mounting();

  return (
    <group position={[0, isA2 ? -0.007 : 0, 0]}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <RobotPart path={isA2 ? eeA2QCPath : eeA1QCPath} />
      </group>
      {getEndEffectorNode(endEffectorKind)}
    </group>
  );
}
