import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import type { Group } from 'three';

import { CommonQuaternions } from '@sb/geometry';

import { Axes } from '../axes/Axes';
import { RobotPart } from '../RobotPart';
import type { JointMapping } from '../types';
import { useCopyRobotModelPosition } from '../useCopyRobotModelPosition';
import { useRobotModel } from '../useRobotModel';
import { useRobotUpdateOnStateChange } from '../useRobotUpdateOnStateChange';
import { useVisualizerContext } from '../VisualizerContext';

import basePath from './Base.glb';
import j0Path from './J0.glb';
import j1j2Path from './J1-J2.glb';
import j4Path from './J4.glb';
import j5Path from './J5.glb';
import urdfPath from './modelone.urdf';
import wlj3Path from './WL-J3.glb';

const JOINTS: JointMapping = [
  ['joint0', (state) => state.jointAngles?.[0]],
  ['joint1', (state) => state.jointAngles?.[1]],
  ['joint2', (state) => state.jointAngles?.[2]],
  ['joint3', (state) => state.jointAngles?.[3]],
  ['joint4', (state) => state.jointAngles?.[4]],
  ['joint5', (state) => state.jointAngles?.[5]],
];

interface ArmProps {
  children?: React.ReactNode;
}

export function Arm({ children }: ArmProps) {
  const model = useRobotModel(urdfPath);

  const { overrideState } = useVisualizerContext();

  const isRobotVisible = useRobotUpdateOnStateChange(model, JOINTS);

  const endEffectorGroupRef = useRef<Group>(null!);

  useCopyRobotModelPosition(model.visual.gripper_link, endEffectorGroupRef);

  useFrame(() => {
    if (overrideState?.tooltipPoint) {
      endEffectorGroupRef.current.position.set(
        overrideState.tooltipPoint.x,
        overrideState.tooltipPoint.y,
        overrideState.tooltipPoint.z,
      );

      endEffectorGroupRef.current.quaternion
        .set(
          overrideState.tooltipPoint.i,
          overrideState.tooltipPoint.j,
          overrideState.tooltipPoint.k,
          overrideState.tooltipPoint.w,
        )
        .multiply(CommonQuaternions.ROTATE_X_180)
        .multiply(CommonQuaternions.ROTATE_Z_90);
    }
  });

  // don't render arm if only rendering the tooltip at a specified position
  const shouldArmRender = !overrideState?.tooltipPoint;

  const axesJoints = { ...model.joints };

  if (overrideState?.tooltipPoint) {
    axesJoints.tool_tip = axesJoints.tool_tip.clone();
    axesJoints.tool_tip.parent = null;
    axesJoints.tool_tip.position.x = overrideState.tooltipPoint.x;
    axesJoints.tool_tip.position.y = overrideState.tooltipPoint.y;
    axesJoints.tool_tip.position.z = overrideState.tooltipPoint.z;
  }

  return (
    <group visible={isRobotVisible}>
      <group visible={shouldArmRender}>
        <RobotPart path={basePath} source={model.visual.base_link} />
        <RobotPart path={j0Path} source={model.visual.shoulder_link} />
        <RobotPart path={j1j2Path} source={model.visual.upper_arm_link} />
        <RobotPart path={wlj3Path} source={model.visual.forearm_link} />
        <RobotPart path={j4Path} source={model.visual.wrist_1_link} />
        <RobotPart path={j5Path} source={model.visual.wrist_2_link} />
      </group>

      <group ref={endEffectorGroupRef}>{children}</group>

      <Axes joints={axesJoints} />
    </group>
  );
}
