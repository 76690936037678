import { Suspense } from 'react';

import { LoaderFallback } from '../LoaderFallback';

import { OnRobotDualQuickChanger } from './OnRobotDualQuickChanger/OnRobotDualQuickChanger';
import { QuickChanger } from './QuickChanger/QuickChanger';
import { useEndEffectorKind } from './useEndEffectorKind';

export function EndEffector() {
  const endEffectorKind = useEndEffectorKind();

  return (
    <Suspense fallback={<LoaderFallback />}>
      {endEffectorKind === 'OnRobotDualQuickChanger' ? (
        <OnRobotDualQuickChanger />
      ) : (
        <QuickChanger />
      )}
    </Suspense>
  );
}
