import { Canvas } from '@react-three/fiber';
import React, { Suspense, useMemo } from 'react';
import { DoubleSide, MeshStandardMaterial } from 'three';

import type { KinematicState } from '@sb/routine-runner';

import { GHOST_ROBOT_COLOR } from './consts';
import { Robot } from './Robot';
import { Stage } from './stage/Stage';
import type { AxesMode, OnVisualizerStateChange } from './types';
import { VisualizerContextProvider } from './VisualizerContext';

interface VisualizerSceneProps {
  onStateChange?: OnVisualizerStateChange;
  axesMode?: AxesMode;
  onGhostRobotStateChange?: OnVisualizerStateChange;
  ghostRobotState?: Partial<KinematicState>;
  ghostRobotAxesMode?: AxesMode;
  children?: React.ReactNode;
}

export function VisualizerScene({
  onStateChange,
  axesMode = null,
  onGhostRobotStateChange,
  ghostRobotState,
  ghostRobotAxesMode = null,
  children,
}: VisualizerSceneProps) {
  const ghostMaterial = useMemo(() => {
    return new MeshStandardMaterial({
      color: GHOST_ROBOT_COLOR,
      transparent: true,
      opacity: 0.3,
      side: DoubleSide,
    });
  }, []);

  return (
    <Canvas
      camera={{
        fov: 12,
        aspect: 2,
        near: 1,
        far: 10000,
        position: [-10, 5, -10],
      }}
      flat
      dpr={window.devicePixelRatio}
      frameloop="demand"
    >
      <Stage />

      <group rotation-x={-Math.PI / 2}>
        {onStateChange && (
          <VisualizerContextProvider
            value={{
              onStateChange,
              axesMode,
            }}
          >
            <Robot />
          </VisualizerContextProvider>
        )}

        {onGhostRobotStateChange && (
          <Suspense fallback={null}>
            <VisualizerContextProvider
              value={{
                onStateChange: onGhostRobotStateChange,
                overrideState: ghostRobotState,
                axesMode: ghostRobotAxesMode,
                material: ghostMaterial,
              }}
            >
              <Robot />
            </VisualizerContextProvider>
          </Suspense>
        )}

        {children}
      </group>
    </Canvas>
  );
}
