import { Environment, OrbitControls } from '@react-three/drei';

import { Floor } from './Floor';
import environmentHdr from './try.hdr';

export function Stage() {
  return (
    <>
      <OrbitControls
        panSpeed={0.7}
        rotateSpeed={0.8}
        minDistance={0.01}
        maxDistance={100}
        enableDamping={false}
        target={[0, 1, 0]}
        makeDefault
      />

      <Environment files={environmentHdr} />

      <Floor />
    </>
  );
}
